import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku15.module.scss"

const Luukku15 = props => {
  //console.log('Rendering Luukku15', props)
  const nro = 15
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/15luukku_Joulu.png" alt="" />
      <p>
        Jouluvalot jo loistaa, mutta aurinko ei juuri paistele. Mistä puhtia talven yli jaksamiseen? Milloin viimeksi
        olet mitannut omaan terveyteen ja vireyteen liittyvät arvot?
      </p>
      <p>
        Omaa hyvinvointia voi tarkastella helposti Puhdin tarjoamien testipakettien avulla. Haluaisitko tietää
        rauta-arvosi, D-vitamiinin saantisi, kolesteroliarvot tai teettää laajemmat laboratoriotestit ja päästä kerralla
        jyvälle elimistösi hyvinvoinnista? Olet askeleen verran lähempänä omia tietojasi osallistumalla 100 euron
        Puhti-lahjakortin arvontaan.
      </p>
      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Hurraa, pinkaisit juuri puhtaasti voittoon!" win>
            Lähetämme sinulle pian ohjeet sähköpostiisi voiton lunastusta varten.
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Voi sentään, tällä kertaa voitto ei osunut kohdallesi.">
            <p>
              Mutta jos olet jo Lumme Energian asiakas, saat asiakasetuna Puhti-testeistä alennusta 15 prosentin verran!
              <ExternalLink href="https://tiimi.lumme-energia.fi" children="Klikkaa itsesi Lumme-tiimiin" />
              ja lunasta etusi.
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku15
