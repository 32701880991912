import React from "react"
import * as styles from "./Voittoviesti.module.scss"

const Voittoviesti = props => {
  //console.log('Rendering Voittoviesti', props)
  return (
    <div className={styles.container}>
      <h2 className={props.win ? "purple" : "darkgreen"}>{props.title}</h2>
      <div>{props.children}</div>
    </div>
  )
}

export default Voittoviesti
