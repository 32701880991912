import React from "react"
import { cls, pseudorandom, round } from "../utils"
import { STORAGE_KEY as avatut_key } from "./Joulukalenteri"
import * as styles from "./Palapeli.module.scss"

import p1 from "../images/palat/Pala_1.png"
import p2 from "../images/palat/Pala_2.png"
import p3 from "../images/palat/Pala_3.png"
import p4 from "../images/palat/Pala_4.png"
import p5 from "../images/palat/Pala_5.png"
import p6 from "../images/palat/Pala_6.png"
import p7 from "../images/palat/Pala_7.png"
import p8 from "../images/palat/Pala_8.png"
import p9 from "../images/palat/Pala_9.png"
import p10 from "../images/palat/Pala_10.png"
import p11 from "../images/palat/Pala_11.png"
import p12 from "../images/palat/Pala_12.png"
import p13 from "../images/palat/Pala_13.png"
import p14 from "../images/palat/Pala_14.png"
import p15 from "../images/palat/Pala_15.png"
import p16 from "../images/palat/Pala_16.png"
import p17 from "../images/palat/Pala_17.png"
import p18 from "../images/palat/Pala_18.png"
import p19 from "../images/palat/Pala_19.png"
import p20 from "../images/palat/Pala_20.png"
import p21 from "../images/palat/Pala_21.png"
import p22 from "../images/palat/Pala_22.png"
import p23 from "../images/palat/Pala_23.png"
import p24 from "../images/palat/Pala_24.png"
import { UserContext } from "../context/UserContext"
import { devMode } from "../pages/_etusivu-kalenteri"

/** Palapelin palojen kuvat */
export const pala_imgs = [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  p22,
  p23,
  p24,
]
/*
palapeli 500x750px
*/
const gridX = 4
const gridY = 6
// const gridSize = 125

/** Alustetaan palapelin palat (Nämä tallennetaan UserContextiin) */
export const init_palat = [...Array(24).keys()].map(i => ({
  id: i,
  onBoard: false,
  trgtX: (i % gridX) / gridX,
  trgtY: (Math.floor(i / gridX) % gridY) / gridY,
}))

/** LocalStorage avain, johon palapelin tila tallennetaan */
export const STORAGE_KEY = "palapeli"

const Pala = props => {
  //console.log("pala", props.data.id, props)
  const data = props.data

  // Palan sijainti suhteessa gridiin 0.0 - 1.0. Reunapalat siis 0 tai 1. Koska objectPosition tai backgroundPosition.
  const rx = data.trgtX * (1 + 1 / (gridX - 1))
  const ry = data.trgtY * (1 + 1 / (gridY - 1))

  /*  

  TODO: Kuva asettuu oikein kun kuvan koon mukaan asetetaan objectPositionille tietyllä kertoimella "marginia" tai offsettia, 
  koska kuvasta näytetään suurempi alue kuin gridin kokoinen osa (palan "korvat"). Pitäisi ehkä kehitellä joku algoritmi, 
  jolla kerroin muodostuu. Tässä muutama arvo eri kokoisilla kuvilla. 

  HUOM! $imgSize tulee css-muuttujasta, joka määrittää palan img-elementin koon.

  $imgSize: 125px
  const multiplierX = 0
  const multiplierY = 0

  $imgSize: 200px
  const multiplierX = 1 / gridX
  const multiplierY = 0.8333 / gridY         // (1 - 1 / gridY) 

  $imgSize: 250px
  const multiplierX = 2  / gridX
  const multiplierY = 1.5 / gridY

  $imgSize: 300px
  const multiplierX = 3.5 / gridX
  const multiplierY = 2.3333 / gridY

  $imgSize: 400px
  const multiplierX = 11 / gridX
  const multiplierY = 4.75 / gridY
  */

  const multiplierX = 2 / gridX
  const multiplierY = 1.5 / gridY

  const offsetX = (rx - 0.5) * multiplierX
  const offsetY = (ry - 0.5) * multiplierY

  const px = round((rx + offsetX) * 100, 3)
  const py = round((ry + offsetY) * 100, 3)

  return (
    <div
      className={cls(styles.pala, data.correct && styles.correct)}
      onPointerDown={props.onPointerDown}
      style={{
        top: data.y,
        left: data.x,
        /* background: `url(${pala_imgs[data.id]}) ${px}% ${py}%`, */
      }}>
      <img src={pala_imgs[data.id]} style={{ objectPosition: `${px}% ${py}%` }} alt="" />
      <div className={styles.debug}>
        {devMode && <h2>{data.id}</h2>}
        {/*  <p>{JSON.stringify({ x: round(data.x / (125 * 4), 3), y: round(data.y / (125 * 6), 3) })}</p> */}
        {/* <p>{JSON.stringify({ rx, ry })}</p>
        <p>{JSON.stringify({ px, py })}</p> */}
      </div>

      <div className={styles.hitarea} />
    </div>
  )
}

let boardScale = 1

const Palapeli = props => {
  //console.log('Rendering Palapeli', props)

  const [context, setContext] = React.useContext(UserContext)

  const avatut_luukut = context[avatut_key]

  const state = context[STORAGE_KEY]

  const cont = React.useRef(null)
  const shelf = React.useRef(null)
  const board = React.useRef(null)
  const boardCont = React.useRef(null)

  let dragged = { pala: null, elem: null, ofsX: 0, ofsY: 0 }

  /** Sen containerin skaala, jossa pala tällä hetkellä on */
  let contScale = boardScale

  const isFinished = devMode
    ? avatut_luukut.filter(itm => typeof itm === "number").length === state.filter(pala => pala.correct).length
    : !state.find(pala => !pala.correct)

  React.useEffect(() => {
    window.addEventListener("resize", resize)

    resize()
    function resize(event) {
      if (board && board.current && boardCont && boardCont.current) {
        const boardContRect = boardCont.current.getBoundingClientRect()
        const boardWidth = board.current.offsetWidth
        boardScale = boardContRect.width / boardWidth
        board.current.style.transform = "scale(" + boardScale + ")"
        boardCont.current.style.height = board.current.offsetHeight * boardScale + "px"
      }
    }

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  const startDrag = (event, pala) => {
    if (!dragged.elem && pala) {
      window.addEventListener("pointermove", drag)
      window.addEventListener("pointerup", drop)

      dragged.elem = event.currentTarget
      dragged.pala = pala

      const rect = dragged.elem.getBoundingClientRect()
      //const contOfs = ~state.onShelf.indexOf(dragged.pala)
      const contOfs = dragged.pala.onBoard
        ? { x: cont.current.offsetLeft + board.current.offsetLeft, y: cont.current.offsetTop + board.current.offsetTop }
        : { x: cont.current.offsetLeft, y: cont.current.offsetTop }

      //contScale = ~state.onShelf.indexOf(dragged.pala) ? 1 : boardScale
      contScale = !dragged.pala.onBoard ? 1 : boardScale
      dragged.elem.classList.add(styles.dragged)
      dragged.elem.classList.remove(styles.correct)
      dragged.pala.correct = false
      dragged.ofsX = event.pageX - rect.left + contOfs.x - window.scrollX
      dragged.ofsY = event.pageY - rect.top + contOfs.y - window.scrollY

      drag(event)
    }
  }

  const drag = event => {
    //event.preventDefault()
    if (dragged.elem) {
      dragged.elem.style.top = (event.pageY - dragged.ofsY) / contScale + "px"
      dragged.elem.style.left = (event.pageX - dragged.ofsX) / contScale + "px"
    }
  }

  const drop = event => {
    window.removeEventListener("pointermove", drag)
    window.removeEventListener("pointerup", drop)

    if (dragged.elem) {
      const rect = board.current.getBoundingClientRect()
      const contOfs = { x: cont.current.offsetLeft, y: cont.current.offsetTop }
      const boardOfs = dragged.pala.onBoard
        ? { x: board.current.offsetLeft, y: board.current.offsetTop }
        : { x: 0, y: 0 }

      const px = event.pageX - window.scrollX
      const py = event.pageY - window.scrollY

      dragged.pala.x = (px - rect.left - dragged.ofsX + contOfs.x + boardOfs.x) / boardScale
      dragged.pala.y = (py - rect.top - dragged.ofsY + contOfs.y + boardOfs.y) / boardScale

      const newState = [...state]

      if (px < rect.right && px > rect.left && py > rect.top && py < rect.bottom) {
        console.log("dropped to board")

        // Relative positions on board 0 - 1
        const px = dragged.pala.x / board.current.offsetWidth
        const py = dragged.pala.y / board.current.offsetHeight

        // distance to target position
        const dx = dragged.pala.trgtX - px
        const dy = dragged.pala.trgtY - py
        const distToTarget = Math.sqrt(dx * dx + dy * dy)

        if (distToTarget < 0.1) {
          const r = Math.random() * 0.000001 // add small variation, to ensure state update & render
          dragged.pala.x = dragged.pala.trgtX * board.current.offsetWidth + r
          dragged.pala.y = dragged.pala.trgtY * board.current.offsetHeight + r

          dragged.pala.correct = true
          dragged.elem.classList.add(styles.correct)
        }

        dragged.pala.onBoard = true
      } else {
        console.log("dropped outside board")
        dragged.pala.onBoard = false
      }

      dragged.elem.classList.remove(styles.dragged)
      dragged.elem = null

      setContext({ [STORAGE_KEY]: newState })
    }
  }

  //console.log(state, context)

  if (!state) return null

  // sama seed kun kalenterin luukkujen palojen järjestyksessä
  pseudorandom(2)

  return (
    <div ref={cont} className={cls(styles.container, isFinished && styles.finished)}>
      <div ref={shelf} className={styles.shelf}>
        {state
          .map(pala => {
            const available = ~avatut_luukut.indexOf(pala.id)

            if (pala.onBoard || !available) return null
            return <Pala key={pala.id} data={pala} onPointerDown={e => startDrag(e, pala)} />
          })
          .sort(() => pseudorandom() - 0.5)}
      </div>
      <div ref={boardCont} className={styles.boardCont}>
        <div ref={board} className={styles.board}>
          {state.map(pala => {
            if (!pala.onBoard) return null
            return <Pala key={pala.id} data={pala} onPointerDown={e => startDrag(e, pala)} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Palapeli
