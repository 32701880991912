import { Link } from "gatsby"
import gsap from "gsap/all"
import React from "react"
import * as styles from "./PeliLinkki.module.scss"

const PeliLinkki = props => {
  //console.log('Rendering PeliLinkki', props)
  const counter = React.useRef(null)
  React.useEffect(() => {
    const animDelay = 1.5
    if (props.count && counter.current) {
      gsap.delayedCall(animDelay, () => {
        gsap.fromTo(counter.current, { duration: 0.2, scale: 1 }, { scale: 2 })
        gsap.to(counter.current, { duration: 1, scale: 1, delay: 0.5, ease: "bounce" })
      })
    }
  }, [props.count])

  return (
    <div className={styles.container}>
      <Link to="/palapeli">
        <span className={styles.icon} />
        {props.label}
      </Link>
      {props.count && (
        <div ref={counter} className={styles.counter}>
          {props.count}
        </div>
      )}
    </div>
  )
}

export default PeliLinkki
