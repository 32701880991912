import { Link } from "gatsby"
import React from "react"
import logo from "../images/LOGO.svg"
import pallo1 from "../images/Pallo_1.png"
import pallo2 from "../images/Pallo_2.png"
import pallo3 from "../images/Pallo_3.png"
import pallo4 from "../images/Pallo_4.png"
import pallo5 from "../images/Pallo_5.png"
import topi from "../images/Topseli.png"
import * as styles from "./Header.module.scss"

const Header = props => {
  //console.log('Rendering Header', props)
  return (
    <header className={styles.container}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="Lumme energia logo" />
      </Link>
      <div className={styles.pallot}>
        <img className={styles.pallo} src={pallo1} alt="" />
        <img className={styles.pallo} src={pallo2} alt="" />
        <img className={styles.pallo} src={pallo3} alt="" />
        <img className={styles.pallo} src={pallo4} alt="" />
        <img className={styles.pallo} src={pallo5} alt="" />
      </div>
      <img className={styles.topi} src={topi} alt="Topi Töpseli" />
    </header>
  )
}

export default Header
