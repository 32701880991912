import React from "react"
import { cls } from "../utils"
import { luukkuStatus } from "./Joulukalenteri"
import * as styles from "./Luukku.module.scss"
// import kansi from "../images/Luukku.svg"

/**
 *
 * @param status Joulukalenteri/luukkuStatus
 * @param num number
 * @param onClick handler
 * @returns
 */
const Luukku = props => {
  // console.log("Rendering Luukku", props)

  return (
    <button
      className={cls(
        styles.container,
        props.status === luukkuStatus.OPEN && styles.open,
        props.status === luukkuStatus.AVAILABLE && styles.available,
        props.status === luukkuStatus.CURRENT && styles.current
      )}
      onClick={props.onClick}
      title={"Luukku " + props.num}>
      <div className={styles.avatunLuukunNumero}>{props.num}</div>

      <div className={cls(styles.kannen_tausta)}></div>

      <div className={cls(styles.kansi)}>{props.num}</div>
    </button>
  )
}

export default Luukku
