import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import YoutubeEmbed from "../YoutubeEmbed"
//import * as styles from "./Luukku4.module.scss"

const Luukku4 = props => {
  //console.log('Rendering Luukku4', props)
  const nro = 4
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/4luukku_Joulu.png" alt="" />
      <h1>Voi kylmän kantamoinen!</h1>
      <p>
        Oletko kuullut huhuja, että viileässä nukkuu paremmin? Tai että avantouinti on kuin virtapiikki ja
        vitamiiniannos koko keholle? Ota viileät vinkit vastaan, jotta voit säästää lämmityskustannuksissa ja pitää
        itsesi kunnossa läpi hyisen talven!
      </p>
      <ul>
        <li>
          Jopa 2 asteen lämpötilamuutoksella teet hyvää. Ja mikä parasta, nukut viileässä hieman paremmin. Katso tästä
          videosta miten lämmityskulut vaikuttavat hiilijalanjälkeesi: <YoutubeEmbed youtubeId="FmS8WGfEXlE" />
        </li>
        <li>
          Avantoon? Mutta ei varusteita? Tarvitset vain uikkarit, villasukat, pipon ja (hitusen) rohkeaa asennetta,
          selviät niillä mainiosti! Myös ystävän nappaaminen avantoreissulle on hyväksi. Tsemppi ei ole koskaan hukkaan
          menevää valuuttaa!
        </li>
        <li>
          Ei avantoa lähimaastossa? Hangessa kieriminenkin on hyvä kylmäaltistus ja sen sanotaan tukevan vastustuskykyä
          ja tekevän kaikin puolin paremman olon. Käy hangessa vaikka yöllä, jos päivällä on liikaa yleisöä. Emme
          kuitenkaan suosittele ihan ilkosillaan ja ypöyksin sohjossa pyörimistä, sillä ihmeellisen paljon niitä
          yövuoroon lähtijöitä on myös liikkeellä.
        </li>
        <li>
          Ihanan lämpimän suihkun päälle kylmää kyytiä? Saat veren kiertämään ja hiukset voimaan paremmin, kun annat
          kylmän loppusuihkun koko keholle!
        </li>
        <li>
          Katsooko aamulla peilistä väsynyt ja vähän turvonnut naamaparka? Ei hätää. Pistä illalla pakkaseen pieni,
          kosteutettu pyyhe. Ota se aamulla pakkasesta ja asettele kasvoille. Loikoile sohvalla, kunnes pyyhkeen kylmyys
          on väljähtynyt. Anna kylmän viedä turvotus ja väsymys mennessään ja taas jaksaa!
        </li>
      </ul>
    </>
  )
}

export default Luukku4
