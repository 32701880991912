import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku7.module.scss"

const Luukku7 = props => {
  //console.log('Rendering Luukku7', props)
  const nro = 7
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/7luukku_Joulu.png" alt="" />
      <p>
        Mitä jos sinäkin valitsisit vaatekaappiisi eettisiä ja ekologisia designtuotteita? Tässä kalenteriluukussa voit
        osallistua 100 euron arvoisen lahjakortin arvontaan suomalaiseen Globe Hopen verkkokauppaan. Vastuullisuus ei
        ole ikinä ollut näin tyylikästä!
      </p>
      <p>
        Tässä luukussa arvomme 6 kk lahjakortin Nextoryyn. Ihastuttavalla aineettomalla lahjalla saat puolen vuoden ajan
        lukea e-kirjoja sydämesi kyllyydestä tai uppoutua äänikirjojen maailmaan. Kokeile tuuriasi arvonnassa ja lumoudu
        lukemiseen!
      </p>

      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onnittelut, voitit juuri 100 euron arvoisen lahjakortin Globe Hopen verkkokauppaan!" win>
            Lähetämme lahjakortin sähköpostiisi mahdollisimman pian, jotta pääsisit hellimään vaatekaappiasi eettisillä
            ja ekologisilla designtuotteilla.
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Arpaonni ei tällä kertaa suosinut sinua, mutta mitä jos saisit sen sijaan ostoksistasi vähän alennusta?">
            <p>
              Tiesithän, että Lumme Energian asiakkaana saat 15 % alennusta Globe Hopen verkkokaupasta.
              <ExternalLink href="https://tiimi.lumme-energia.fi" children="Lunasta etusi" /> kirjautumalla vaikka heti
              Lumme-tiimiin.
            </p>
            <p>
              Muista myös palapelimme. Kaikki palat kerättyäsi voit osallistua pääarvontaan, joka on 250 euron arvoinen
              S-ryhmän majoituslahjakortti.
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku7
