import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku17.module.scss"

const Luukku17 = props => {
  //console.log('Rendering Luukku17', props)
  const nro = 17
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/17luukku_Joulu.png" alt="" />
      <h1>Veimme lahjoituksemme vesille!</h1>
      <p>
        Tänään luukusta avautuu 300 euron arvoinen lahjoitus Pidä Saaristo Siistinä -ympäristöjärjestölle, joka edistää
        Suomen meri- ja järviluonnon hyvinvointia tärkeällä puhdistustyöllä. Lahjoituksen avulla parannamme rakkaiden
        vesistöjemme puhtautta ja vähennämme niiden rehevöitymistä. Me Lumme Energialla haluamme kääriä hihamme puhtaan
        vesistön ja meri- ja järviluonnon hyvinvoinnin puolesta.
      </p>
      <p>
        Teimme siistin teon vesistölle. Teetkö sinäkin? Lahjoittaa voi{" "}
        <ExternalLink href="https://pidasaaristosiistina.fi" children="pidasaaristosiistina.fi" />. Voit olla
        ympäristömme puolella myös ilman lahjoitustakin, sillä kaikki hyvä lähtee pienistä, päivittäisistä teoista.
        Esimerkiksi nostamalla maasta löydetyn tupakantumpin roskiin, teet kauniin teon. Tumppi maatuu vasta kymmenen
        vuoden päästä ja silloinkin se muuttuu mikromuoviksi, joka jatkaa haitallista matkaansa vesistöissä. Kerätään
        yhdessä roska päivässä!
      </p>
    </>
  )
}

export default Luukku17
