import React from "react"
import { UserContext } from "../context/UserContext"
import { pseudorandom } from "../utils"
import * as styles from "./Lomake.module.scss"
import { gtag } from "./SEO"
import Voittoviesti from "./Voittoviesti"

export const apiBaseUrl = "https://europe-west2-jou-lumme.cloudfunctions.net/joulummeApi/"

const prizeMap = {}

const formLifecycle = {
  NORMAL: "normal",
  WAITING: "waiting",
  SUCCESS: "success",
  ERROR: "error",
  CLOSED: "closed",
}
/**
 *
 * @param apiUrl string
 * @param draw required. Forms localStorage id ja query param.
 * @param seed seed for token
 * @param disabled boolean. Hide form and show closedContent
 * @param closedContent Content when form is not enabled
 * @param winContent Content when form return a win
 * @param loseContent Content when from return success, but not win
 * @param children Content on top of the form
 * @returns
 */
const Lomake = props => {
  //console.log('Rendering Lomake', props)

  const date = new Date()
  const seed = date.getUTCDate() //props.seed || date.getDate()
  const draw = props.draw
  const enabled = !props.disabled
  const token = Math.floor(pseudorandom(seed) * 1000000)

  const [context, setContext] = React.useContext(UserContext)
  /** Onko peliin jo osallistuttu aiemmin */
  const drawStatus = typeof context.draws[draw] !== "undefined"
  /** Tuliko silloin voitto */
  const winStatus = !!context.draws[draw]

  const [state, setState] = React.useState({
    formLifecycle: enabled ? (drawStatus ? formLifecycle.SUCCESS : formLifecycle.NORMAL) : formLifecycle.CLOSED,
    winStatus: winStatus,
    errorMessage: "Virhe tapahtui. Yritä uudelleen!",
  })
  const form = React.useRef(null)

  const sendClick = e => {
    if (form.current.checkValidity()) {
      e.preventDefault()

      gtag("Lomake", "click", "Lähetä")

      setState({ ...state, formLifecycle: formLifecycle.WAITING })

      const query = { email: form.current.email.value, draw: draw, token: token }

      window
        .fetch(apiBaseUrl + props.apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(query),
        })
        .then(async res => {
          const data = await res.json()
          let errorMsg = ""

          switch (res.status) {
            case 200:
              console.debug("Fetched", data)
              let win = false

              if (data.prize) {
                win = true
              }

              setContext({ draws: { ...context.draws, [draw]: win } })
              setState({ ...state, formLifecycle: formLifecycle.SUCCESS, winStatus: win })
              return // exit

            case 429: // daily limit reached
              errorMsg = "Olet ylittänyt tämän päivän pelirajoituksen. Yritä huomenna uudelleen!"
              break
            case undefined:
              errorMsg = `Tuntematon virhe tapahtui.`
              break
            default:
              errorMsg = `${res.status} - ${data.error}`
              break
          }

          console.debug("Request error", res, data)
          setState({ ...state, formLifecycle: formLifecycle.ERROR, errorMessage: errorMsg })
        })
        .catch(err => {
          console.debug("Request failed", err)
          let errorMsg = "Virhe tapahtui: " + err
          setState({ ...state, formLifecycle: formLifecycle.ERROR, errorMessage: errorMsg })
        })
    }
  }

  if (state.formLifecycle === formLifecycle.CLOSED) {
    return (
      <>
        {props.closedContent ? (
          props.closedContent
        ) : (
          <div>
            <Voittoviesti title="Arvonta on päättynyt.">Olemme voittajiin yhteydessä henkilökohtaisesti.</Voittoviesti>
          </div>
        )}
      </>
    )
  }

  if (state.formLifecycle === formLifecycle.SUCCESS) {
    return (
      <>
        {state.winStatus === true ? (
          <>
            {props.winContent ? (
              props.winContent
            ) : (
              <Voittoviesti title="Hurraa, voitit palkinnon!" win>
                Lähetämme sinulle sähköpostiisi ohjeet voiton lunastamiseksi.
              </Voittoviesti>
            )}
          </>
        ) : (
          <>
            {props.loseContent ? (
              props.loseContent
            ) : (
              <Voittoviesti title="Kiitos osallistumisesta!">
                Olemme voittajiin yhteydessä henkilökohtaisesti.
              </Voittoviesti>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div style={{ marginTop: "4em" }}>
      <form ref={form} className={styles[state.formLifecycle]}>
        {props.children}
        <input
          disabled={state.formLifecycle === formLifecycle.WAITING}
          type="email"
          name="email"
          placeholder="Sähköpostiosoite"
          aria-label="Sähköpostiosoite"
          required
        />
        <button disabled={state.formLifecycle === formLifecycle.WAITING} onClick={sendClick}>
          {state.formLifecycle === formLifecycle.WAITING ? "Lähetetään..." : "Lähetä"}
        </button>
        {state.formLifecycle === formLifecycle.ERROR && <div style={{ color: "darkred" }}>{state.errorMessage}</div>}
      </form>
    </div>
  )
}

export default Lomake
