import React from "react"
import { createPortal } from "react-dom"
import { IoIosClose } from "react-icons/io"
import * as styles from "./Modal.module.scss"

const Modal = props => {
  //console.log('Rendering Modal', props)

  const container = React.useRef(null)

  const onClose = props.onClose ? props.onClose : () => {}

  const reFocus = (/*e*/) => {
    // palauttaa focuksen sisällön ensimmäiseen elementtiin
    if (container.current !== null) {
      const focusableElements = "a[href], area[href], button, input, textarea, select, iframe"
      const elem = container.current.querySelectorAll(focusableElements)[0]

      if (elem) elem.focus()
    }
  }

  React.useEffect(() => {
    reFocus()

    // popupin sulkeminen näppäimistöllä
    window.addEventListener("keydown", keydown)

    function keydown(e) {
      switch (e.key) {
        case "Tab": // tab
          break
        case "Escape": // esc
          onClose()
          break
      }
    }

    return () => {
      window.removeEventListener("keydown", keydown)
    }
  }, [])

  if (typeof document === "undefined") return null

  return (
    <>
      {createPortal(
        <div className={styles.container} ref={container}>
          <div
            className={styles.backdrop}
            aria-modal={true}
            onClick={() => {
              onClose()
            }}
          />
          <div className={styles.contentAlign}>
            <div className={styles.content}>{props.children}</div>
            <button
              className={styles.closeButton}
              onClick={() => {
                onClose()
              }}
              onBlur={reFocus}
              title="Close"
              tabIndex={0}>
              <IoIosClose />
            </button>
          </div>

          {/* Pitää olla joku focus-kohde reFocus-napin jälkeen, jotta focus ei hyppää ulos selaimesta */}
          <div aria-hidden={true} tabIndex={0} className="sr-only" />
        </div>,
        document.body
      )}
    </>
  )
}

export default Modal
