import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//import * as styles from "./Luukku5.module.scss"

const Luukku5 = props => {
  //console.log('Rendering Luukku5', props)
  const nro = 5
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/5luukku_Joulu.png" alt="" />
      <p>
        Vähemmän joulustressiä ja enemmän joulurauhaa? Jouluinen muistilistamme auttaa asiassa. Ota vinkit käyttöön ja
        anna kiireen keventyä hitusen!
      </p>
      <ul>
        <li>
          Puolita jouluruoan ostoslista. Tapaninpäivänä ihan tavallinen perusruokakin maistuu taivaallisen hyvälle.
        </li>
        <li>
          Anna lahjaksi lumien luonti, kakkukahvit, metsäretki tai ikkunoiden pesu. Lahjan ohessa tulee sinun seurasi,
          joten se on varmasti läheiselle lahjoista parhain.
        </li>
        <li>
          Paketoi kierrättäen. Vanha sanomalehti ja juuttinaru ovat hauska yhdistelmä. Säästät rahaa ja teet pienen
          ympäristöteon!
        </li>
        <li>Joulu on ihana ilman suursiivoustakin. Kynttilöillä lempeää valoa ja villasukat jalassa viltin alle. </li>
        <li>Kumpi jää joulusta mieleen paremmin: iloinen perheenjäsen vai puhdas eteisen kaappi? </li>
        <li>
          Anna joulukuun jokaisena päivänä itsellesi kehu. Keksi aina uusi kehunaihe ja sano se itsellesi peilin edessä.
          Aluksi kehuminen voi tökkiä, mutta ennen kuin huomaatkaan, jäät hommaan positiivisesti kiinni.
        </li>
      </ul>
    </>
  )
}

export default Luukku5
