import React from "react"
import ExternalLink from "../ExternalLink"
import YoutubeEmbed from "../YoutubeEmbed"
//import * as styles from "./Luukku19.module.scss"

const Luukku19 = props => {
  //console.log('Rendering Luukku19', props)
  const nro = 19
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <YoutubeEmbed youtubeId="3cYjkNomOgM" />
      <h1>Hyvää joulua eläimille!</h1>
      <p>
        Suomen Eläinsuojelu SEY on Suomen suurin eläinsuojelujärjestö ja eläinsuojelun asiantuntija. SEY toimii kaikkien
        eläinten hyvinvoinnin puolesta, auttaa hädässä olevia eläimiä, vaikuttaa lainsäädäntöön, jakaa tärkeää tietoa ja
        tekee näkymättömiä asioita näkyviksi ottamalla kantaa ja tiedottamalla. Tärkeää työtä kaikkien apua tarvitsevien
        eläimien puolesta.
      </p>
      <p>
        Eläimille tuotetaan edelleen monenlaista kärsimystä. SEY:n työ on monelle eläimelle elintärkeää. Haluamme olla
        mukana auttamassa hädässä olevia eläimiä, mistä syystä tämän päiväinen luukkumme 300 euron arvoinen lahjoitus
        kolahtaa juurikin SEY:n toiminnan hyväksi.
      </p>
      <p>
        Tee sinäkin lahjoitus eläinten hyväksi osoitteessa <ExternalLink href="https://sey.fi" children="sey.fi" />
      </p>
    </>
  )
}

export default Luukku19
