import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku22.module.scss"

const Luukku22 = props => {
  //console.log('Rendering Luukku22', props)
  const nro = 22
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/22_luukku.png" alt="" />
      <h1>Tsirp, sanoi lintu, joka talipallon näki!</h1>
      <p>Älä unohda pihapiirin pikkulintujen joulua vaan anna heillekin omatekemäsi lahja, joka maistuu pitkään.</p>
      <p>
        <b>Lintujen makoisaa herkkua varten tarvitset:</b>
      </p>
      <p>Paketillisen kookosrasvaa</p>
      <p>5 dl auringonkukan siemeniä</p>
      <p>
        Sulata rasva, lisää joukkoon siemenet ja kaada seos joko kakkuvuokaan tai muffinivuokiin. Anna seoksen jäähtyä.
        Jos teit kakkuvuokaversion, saat ripustettua kakun narun avulla roikkumaan pihapuuhun. Jos taas valitsit
        muffinit, voit sijoittaa niitä lintulaudalle. Älä kuitenkaan aseta lahjaasi maahan, sillä se kerää kutsumattomia
        häntäkavereita apajille!
      </p>
    </>
  )
}

export default Luukku22
