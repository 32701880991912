import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
//import * as styles from "./Luukku9.module.scss"

const Luukku9 = props => {
  //console.log('Rendering Luukku9', props)
  const nro = 9
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/9luukku_Joulu.png" alt="" />
      <p>
        Mitä jos uppoutuisitkin kulttuuriin? Kovia kokenut kulttuuriala kaipaa nyt entistä enemmän uusia ystäviä ja
        tukijoita. Tämän päivän arvonnassa voit voittaa Museokortin. Taiteen todellisen ystävän kanta-asiakaskortilla
        pääset vierailemaan yli 300 museossa ilman erillistä pääsymaksua koko vuoden ajan.
      </p>

      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onnea, voitit pääsylipun yli 300 museoon!" win>
            Museokortilla vierailet niin Suomen merkittävimmissä museokohteissa kuin paikallisissa löydöissä ilman
            erillistä pääsymaksua. Kortti on voimassa 12 kuukautta ensimmäisestä museokäynnistäsi alkaen. Lähetämme
            ohjeet sähköpostiisi palkintosi lunastamiselle!
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Pahus, ei voittoa. Huomenna uusi yritys?">
            Muista myös hauska palapelimme. Kaikki palat kerättyäsi voit osallistua pääarvontaan, joka on 250 euron
            arvoinen S-ryhmän majoituslahjakortti.
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku9
