import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku14.module.scss"

const Luukku14 = props => {
  //console.log('Rendering Luukku14', props)
  const nro = 14
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/14luukku_Joulu.png" alt="" />
      <p>
        Koronasta johtuva poikkeusaika on ollut nuorelle väestöllemme erityisen raskasta. Yksinäisyys ja epävarmuus
        aiheuttavat painetta tulevaisuudesta. Yhä useampi tarvitsee tukea elämän vaikeisiin hetkiin ja hakee apua
        mielenterveyden palveluista. Tämänpäiväinen luukkumme on 300 euron lahjoitus Mieli ry:lle, joka tarjoaa
        maksutonta ja luottamuksellista kriisiapua lapsille ja nuorille.
      </p>
      <p>
        Jos voit itse huonosti, älä epäröi hakea apua. Keskusteluapu chatin tai puhelimen kautta ovat pienen kynnyksen
        kanavia avunsaantiin. Tarjolla on myös vertaistukiryhmiä. Lisätietoja löydät osoitteesta{" "}
        <ExternalLink href="https://mieli.fi" children="mieli.fi" />.
      </p>
    </>
  )
}

export default Luukku14
