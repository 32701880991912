import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku11.module.scss"

const Luukku11 = props => {
  //console.log('Rendering Luukku11', props)
  const nro = 11
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/11luukku_Joulu.png" alt="" />
      <h1>Mitä jos heittäytyisit musisoimaan?</h1>
      <p>
        Rockway on musiikinopetuspalvelu, josta löytyy nuotit, tabit, taustanauhat ja mieletön määrä uskomattomia
        soitinkursseja, joiden avulla opit soittamaan valitsemaasi instrumenttia huippumuusikon ohjauksessa.
      </p>
      <p>
        Osallistumalla päivän arvontaan voit voittaa kolmen kuukauden rajattoman pääsyn Rockwayn palveluihin. Olit
        sitten jo kokeneempi muusikko tai lisävirettä kaipaava karaokeintoilija, Rockwayn kursseista löytyy jokaiselle
        jotakin.
      </p>

      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onneksi olkoon satakieli!" win>
            Arpaonni osui sinuun ja voitit kolmen kuukauden rajattoman pääsyn Rockwayn musiikinopetuspalveluihin. Saat
            koodin lahjakortin lunastamiseen sähköpostiisi. Nyt vain valitsemaan omat suosikit monipuolisista
            soitinkursseista.
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Pahus, tällä kertaa voitto ei osunut kohdallesi.">
            <p>
              Voittoa ei tänään lohjennut, mutta Rockwayn kursseilla voit rokata huomennakin. Lumme Energian asiakkaana
              pääset kokeilemaan kursseja ilmaiseksi kuukauden ajan. Opettele komea kitarariffi tai hurmaa
              lauantai-illan karaokessa!{" "}
              <ExternalLink href="https://tiimi.lumme-energia.fi" children="Kirjaudu Lumme-tiimiin" /> ja pistä Rockway
              kokeiluun!
            </p>
            <p>
              Ja muistathan kerätä tämänpäiväisen palapelin palan. Kaikki palat kerättyäsi ja arvontaan osallistuttuasi
              olet mukana huikean 250 euron arvoisen S-ryhmän majoituslahjakortin arvonnassa.
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku11
