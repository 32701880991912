import * as React from "react"
import Joulukalenteri from "../components/Joulukalenteri"
import { STORAGE_KEY as palapeli_key } from "../components/Palapeli"
import { STORAGE_KEY as avatut_key } from "../components/Joulukalenteri"
import Modal from "../components/Modal"
import { CSSTransition } from "react-transition-group"
import "../styles/global.scss"
import PeliLinkki from "../components/PeliLinkki"
import { UserContext } from "../context/UserContext"
import Header from "../components/Header"
import { reset } from "../utils"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import Luukku1 from "../components/LuukkuContent/Luukku1"
import Luukku2 from "../components/LuukkuContent/Luukku2"
import Luukku3 from "../components/LuukkuContent/Luukku3"
import Luukku4 from "../components/LuukkuContent/Luukku4"
import Luukku5 from "../components/LuukkuContent/Luukku5"
import Luukku6 from "../components/LuukkuContent/Luukku6"
import Luukku7 from "../components/LuukkuContent/Luukku7"
import Luukku8 from "../components/LuukkuContent/Luukku8"
import Luukku9 from "../components/LuukkuContent/Luukku9"
import Luukku10 from "../components/LuukkuContent/Luukku10"
import Luukku11 from "../components/LuukkuContent/Luukku11"
import Luukku12 from "../components/LuukkuContent/Luukku12"
import Luukku13 from "../components/LuukkuContent/Luukku13"
import Luukku14 from "../components/LuukkuContent/Luukku14"
import Luukku15 from "../components/LuukkuContent/Luukku15"
import Luukku16 from "../components/LuukkuContent/Luukku16"
import Luukku17 from "../components/LuukkuContent/Luukku17"
import Luukku18 from "../components/LuukkuContent/Luukku18"
import Luukku19 from "../components/LuukkuContent/Luukku19"
import Luukku20 from "../components/LuukkuContent/Luukku20"
import Luukku21 from "../components/LuukkuContent/Luukku21"
import Luukku22 from "../components/LuukkuContent/Luukku22"
import Luukku23 from "../components/LuukkuContent/Luukku23"
import Luukku24 from "../components/LuukkuContent/Luukku24"
import ExternalLink from "../components/ExternalLink"
import { endOfCampaign, endOfChristmas, now } from "."

/** Application is in development mode */
export const devMode = process.env.NODE_ENV !== "production" && true

export const luukkuContentMap = {
  1: <Luukku1 />,
  2: <Luukku2 />,
  3: <Luukku3 />,
  4: <Luukku4 />,
  5: <Luukku5 />,
  6: <Luukku6 />,
  7: <Luukku7 />,
  8: <Luukku8 />,
  9: <Luukku9 />,
  10: <Luukku10 />,
  11: <Luukku11 />,
  12: <Luukku12 />,
  13: <Luukku13 />,
  14: <Luukku14 />,
  15: <Luukku15 />,
  16: <Luukku16 />,
  17: <Luukku17 />,
  18: <Luukku18 />,
  19: <Luukku19 />,
  20: <Luukku20 />,
  21: <Luukku21 />,
  22: <Luukku22 />,
  23: <Luukku23 />,
  24: <Luukku24 />,
}

const Kalenterisivu = () => {
  const [context, setContext] = React.useContext(UserContext)
  const content = React.useRef(null)
  const avatutLuukut = context[avatut_key]
  const palapeli = context[palapeli_key]

  const christmasHasEnded = now > endOfChristmas //|| devMode
  const campaignHasEnded = now > endOfCampaign //|| devMode

  /** Onko joku luukku avattu / Modal näkyvissä */
  const [state, setState] = React.useState({ open: false, luukku: null })

  const putState = val => {
    setState({ ...state, ...val })
  }

  const onLuukkuOpen = luukku => {
    //console.log(luukku)
    putState({ open: true, luukku: luukku.ind + 1 })
  }

  React.useEffect(() => {
    content.current && content.current.scrollIntoView({ behavior: "smooth" })
  }, [state.luukku])

  //console.log(state)

  const count =
    avatutLuukut.filter(itm => typeof itm === "number").length - palapeli.filter(pala => pala.onBoard).length

  return (
    <>
      <SEO
        title="Tervetuloa vastustamattoman joulukalenterin pariin"
        description="Heitä joulustressille hetkeksi hyvästit ja heittäydy viettämään energistä joulua kanssamme. Olemme koonneet
          joulukalenteriimme roppakaupalla hyvää mieltä, hauskoja DIY-hetkiä, mukavia arvontoja ja vastuullisia
          lahjoituskohteita.  "
      />
      <Header />
      <main>
        {devMode && <p>! Test mode active !</p>}
        {christmasHasEnded ? (
          <>
            {campaignHasEnded ? (
              // Kampanja loppunut
              <>
                <p className="pre-heading">Lumme Energia esittää:</p>
                <h1>Joulun vastustamat&shy;tomin joulu&shy;kalenteri</h1>
                <p>
                  Kiitos, kun olit kanssamme toteuttamassa joulukalenteriamme! Toivottavasti sait kivoja DIY-vinkkejä,
                  lisätietoa tärkeistä avustuskohteista ja jännitit arvonnan voittoja. Nähdään täällä taas ensi jouluna!
                  Sillä välin voit virittäytyä vähän rokimpaan tunnelmaan{" "}
                  <ExternalLink href="https://youtube.com/watch/lx1Cj9REbRY">Sähkösoppari-biisillämme</ExternalLink>.
                </p>
                <p>
                  Energisin terveisin,
                  <br />
                  Lumme Energian väki
                </p>
              </>
            ) : (
              // Joulun jälkeen kun kampanja vielä käynnissä
              <>
                <p className="pre-heading">Lumme Energia esittää:</p>
                <h1>Joulun vastustamat&shy;tomin joulu&shy;palapeli</h1>

                <p>
                  Kiitos, kun olit kanssamme toteuttamassa joulukalenteriamme! Toivottavasti sait kivoja DIY-vinkkejä,
                  lisätietoa tärkeistä avustuskohteista ja jännitit arvonnan voittoja.
                </p>
                <p>
                  Mutta peli ei ole vielä menetetty, sillä sinulla on edelleen mahdollisuus pelata palapeliämme ja sen
                  kokonaan koottuasi osallistua S-ryhmän 250 euron majoituslahjakortin arvontaan. Arvonta suoritetaan
                  3.1.2022 eli vielä on tovi aikaa!
                </p>
              </>
            )}
          </>
        ) : (
          // Perus sisältö
          <>
            <p className="pre-heading">Lumme Energia esittää:</p>
            <h1>Joulun vastustamat&shy;tomin joulu&shy;kalenteri</h1>

            <p>
              Heitä joulustressille heipat ja virittäydy joulunviettoon kanssamme! Joulukalenterimme on täynnä hyvää
              mieltä, upeita arvontoja ja vastuullisia lahjoituksia.
            </p>
            <p>
              Jokainen kalenteriluukku sisältää myös palapelin palan. Kaikki palapelin koonneet pääsevät osallistumaan
              pääpalkinnon arvontaan!
            </p>
            <p>Tunnelmallista joulunaikaa!</p>
          </>
        )}

        <Joulukalenteri onOpen={onLuukkuOpen} />

        <PeliLinkki label="Palapeli" count={count} />

        {luukkuContentMap[state.luukku] && (
          <CSSTransition classNames="fade" in={state.open} timeout={500} unmountOnExit>
            <Modal onClose={() => putState({ open: false })}>
              <article ref={content}>{luukkuContentMap[state.luukku]}</article>
            </Modal>
          </CSSTransition>
        )}
        <nav className="bottom-nav">
          {devMode && (
            <div>
              <button onClick={reset}>Reset (dev)</button>
            </div>
          )}
        </nav>
      </main>
      <Footer />
    </>
  )
}

export default Kalenterisivu
