import React from "react"
import YoutubeEmbed from "../YoutubeEmbed"
//import * as styles from "./Luukku8.module.scss"

const Luukku8 = props => {
  //console.log('Rendering Luukku8', props)
  const nro = 8
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <YoutubeEmbed youtubeId="lx1Cj9REbRY" />
      <p>
        Joulu tulla jollottaa sulosävelien siivittämänä! Kulkusia kuunnellessa sitä virittyy heti juhlakauden
        tunnelmaan.
      </p>
      <p>
        Mutta jos suomalaisen musiikin päivää tekisi mieli viettää vähän raskaamman temmon tahtiin, ota kuunteluun
        Sähkösoppari-biisimme. Laula vaikka mukana, videolla on sanatkin jo valmiina.
      </p>
      <p>
        Äläkä unohda jokapäiväistä palapeliämme! Keräämällä kaikki palat voit osallistua S-ryhmän 250 euron arvoisen
        majoituslahjakortin arvontaan!
      </p>
    </>
  )
}

export default Luukku8
