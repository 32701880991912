import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku21.module.scss"

const Luukku21 = props => {
  //console.log('Rendering Luukku21', props)
  const nro = 21
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/21_luukku.png" alt="" />
      <p>
        Lumi on ehkä jo peittänyt kukat laaksosessa, mutta vastuullisesti valmistetuilla ulkoilu- ja urheiluvaatteilla
        pääset reippailemaan myös talvipakkasessa. Osallistu arvontaan, jossa voit voittaa 100 euron arvoisen
        lahjakortin suomalaiseen Weekendbee-verkkokauppaan, josta löydät vain vastuullisesti valmistettuja urheilu- ja
        ulkoiluvaatteita.
      </p>
      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onni lähtee ulkoilusta!" win>
            Voitit 100 euron arvoisen lahjakortin Weekendbeen verkkokauppaan, josta löydät vastuullisesti valmistetut
            ulkoilu- ja urheiluvaatteet. Lähetämme ohjeet voiton lunastamiselle sähköpostiisi. Riennä voittopotillasi
            ostoksille!
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Parempi tuuri ensi kerralla.">
            <p>Kurkkaa, mitä huomenna luukusta löytyy!</p>
            <p>
              Voitto ei osunut tällä kertaa kohdallesi, mutta onneksi Weekendbeen ulkoilu- ja urheiluvaatteet
              lämmittävät sekä mieltä että kehoa. Lumme Energian saat 15 % alennusta Weekendbeen verkkokaupasta.{" "}
              <ExternalLink href="https://tiimi.lumme-energia.fi" children="Kirjaudu sisään Lumme-tiimiin" /> ja riennä
              ostoksille.
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku21
