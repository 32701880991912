// extracted by mini-css-extract-plugin
export var container = "Palapeli-module--container--nEPZM";
export var boardCont = "Palapeli-module--boardCont--KqCkU";
export var board = "Palapeli-module--board--YYbII";
export var pala = "Palapeli-module--pala--Rm10W";
export var shelf = "Palapeli-module--shelf--ecD6A";
export var dragged = "Palapeli-module--dragged--RrdvK";
export var hitarea = "Palapeli-module--hitarea--0MoDL";
export var debug = "Palapeli-module--debug--VZUnA";
export var correct = "Palapeli-module--correct--LfUxy";
export var finished = "Palapeli-module--finished--iWfIf";