import React from "react"
import * as styles from "./YoutubeEmbed.module.scss"

const YoutubeEmbed = ({ youtubeId }) => {
  //console.log('Rendering YoutubeEmbed', props)

  return (
    <div className={styles.container}>
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

export default YoutubeEmbed
