import React from "react"
import { init_palat, STORAGE_KEY as palapeli_key } from "../components/Palapeli"
import { STORAGE_KEY as avatut_key } from "../components/Joulukalenteri"
import { devMode } from "../pages/_etusivu-kalenteri"

const STORAGE_KEY = "joulumme_userstate"
const initContext = { [avatut_key]: [], [palapeli_key]: init_palat, draws: {} }

export const UserContext = React.createContext([initContext, () => {}])

const UserContextProvider = props => {
  const setContext = newValue => {
    const newState = { ...state[0], ...newValue }
    devMode && console.log("Update context:", newState)
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newState))
    setState([newState, setContext])
  }

  // init state
  React.useEffect(() => {
    const newState = JSON.parse(localStorage.getItem(STORAGE_KEY))
    devMode && console.log("Init context:", newState)
    newState && setContext(newState)
  }, [])

  const initState = [initContext, setContext]

  const [state, setState] = React.useState(initState)

  return <UserContext.Provider value={[state[0], setContext]}>{props.children}</UserContext.Provider>
}

export default UserContextProvider
