import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//import * as styles from "./Luukku20.module.scss"

const Luukku20 = props => {
  //console.log('Rendering Luukku20', props)
  const nro = 20
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/20luukku_Joulu.png" alt="" />
      <h1>Onko kaapissa pientä lasipurkkia, ja peruselintarvikkeita?</h1>
      <p>
        Jos kyllä, et parhaassa tapauksessa tarvitse kaupasta muuta kuin sinappijauhetta ja sinappilahja on tekemistä
        vaille valmis. Sinappi on siitä oiva lahja, että se valmistuu nopeasti ja voit varioida lahjoja erilaisin mauin.
        Valkosipulia, konjakkia, hunajaa, puolukkaa? Mikä sinulle tai lahjan saajalle maistuisi?
      </p>
      <h2>Pieni sinappilahja</h2>
      <p>
        1 dl sinappijauhetta
        <br />
        1 dl sokeria
        <br />
        0,5 dl vehnäjauhoja
        <br />
        0,5 rkl etikkaa
        <br />
        1 rkl vettä
        <br />
        0,5 dl siirappia
        <br />
        suolaa
      </p>
      <p>
        Sekoita kuivat aineet ensin keskenään kattilassa ja lisää sitten etikka ja vesi. Kuumenna, mutta ole varovainen,
        sillä sinappi palaa hanakasti pohjaan. Lisää siirappi ja maista. Anna kiehua hiljalleen. Lisää vielä suolaa maun
        mukaan.
      </p>
      <p>
        Anna sinapin jäähtyä. Kun pakkaat sinappia lasipurkkiin, älä laita purkkia aivan täyteen, sillä sinappi turpoaa
        vielä valmistuksen jälkeenkin. Avot, näin lahja on valmis!
      </p>
      <p>
        Muista myös käydä nappaamassa palapelimme paloja ja olla valmiudessa suurta pääarvontaamme varten, jonka
        palkintona on 250 euron arvoinen S-ryhmän majoituslahjakortti! Sekin olisi aika makea lahja jaettavaksi vaikka
        ystävän kanssa.
      </p>
    </>
  )
}

export default Luukku20
