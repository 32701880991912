import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku13.module.scss"

const Luukku13 = props => {
  //console.log('Rendering Luukku13', props)
  const nro = 13
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/13luukku_Joulu.png" alt="" />
      <p>
        Tiesitkö, että suomalaisista eläkeläisistä yli kolmannes kärsii yksinäisyydestä? Yksinäisyyden tiedetään
        vaikuttavan heikentävästi hyvinvointiin, toimintakykyyn ja terveyteen. Yksinäisyyttä voi ennaltaehkäistä
        viettämällä aikaa läheisten kanssa tai puuhailemalla mielekkäiden harrastusten parissa. Moni meistä ei
        valitettavasti ehdi välimatkan tai arjen kiireiden takia tavata omia läheisiä ikäihmisiään niin usein kuin
        haluaisi. Silloin apuun tulee Gubbe, jonka missiona on auttaa ikäihmisiä viettämään onnellisempaa ja
        aktiivisempaa elämää. Gubbe on uudenlainen vanhuspalvelu, joka pitää ikäihmisen aktiivisena, auttaa arjen
        askareissa sekä samalla tuo turvaa koko perhepiirille. Lucian päivän kunniaksi yksi onnekas voittaa
        luukkuarvonnassamme valoa ja iloa tuovan Gubben viikoittaisen käynnin huikean kolmen kuukauden ajaksi itselleen
        tai omalle läheiselleen. Arvontapalkinnon tarjoaa Lumme Energia ja Gubbe yhdessä.
      </p>

      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onnea!" win>
            Voitit Gubben viikoittaiset käynnit kolmeksi kuukaudeksi itsellesi tai eläkeikäiselle läheisellesi!
            Lähetämme pian ohjeet palkintosi lunastamiselle sähköpostiisi.
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Ei voittoa.">
            <p>
              Onnetar ei valitettavasti tällä kertaa osunut sinuun, mutta haluamme tarjota lohdutuspalkintona
              läheisellesi 3 viikon ilmaiset Gubbe-käynnit (arvoltaan n. 300e). Jos kiinnostuit, voit lunastaa
              lohdutuspalkintosi{" "}
              <ExternalLink
                href="https://fi.gubbe.com/3-viikon-ilmaiskokeilu-lumme?utm_source=lummeswebsite&utm_medium=giveaway&utm_campaign=Lumme&utm_id=lummegiveaway"
                children="täältä"
              />
              !
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku13
