import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku24.module.scss"

const Luukku24 = props => {
  //console.log('Rendering Luukku24', props)
  const nro = 24
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/24_luukku.jpg" alt="" />
      <h1>Hyvää ja vastuullista joulua! </h1>
      <p>
        Miten erityiseltä kuulostaisikin ikioma nimikkolohi, joka ui järvessä sinun nimelläsi? Sitä ei toki ole
        tarkoitus pyydystää ruokapöytään, vaan sillä pyritään elvyttämään järvilohikantaa, joka muuten on vielä Saimaan
        norppaakin uhanalaisempi. Arvomme kymmenelle osallistujalle 5 nimikkolohta! Saat jokaisen järvilohen
        merkintätietokantaan oman nimesi. Jos nimelläsi varustettu lohi myöhemmin tarttuu haaviin ja sen tiedot
        skannataan ja raportoidaan ennen vapauttamistaan takaisin järveen, saat siitä tiedon itsellesi sähköpostiin.
        Tästä infosta selviää, missä kala on tavattu ja miten se on kasvanut. Alamittaiset lohet vapautetaan mittausten
        jälkeen takaisin järveen.
      </p>
      <p>
        Kiinnostuitko lukemaan lisää hankkeesta?{" "}
        <ExternalLink href="https://www.elinvoimainenjarvilohi.fi" children="www.elinvoimainenjarvilohi.fi" />
      </p>
      <p>
        Osallistut arvontaan syöttämällä sähköpostisi ja nimesi alla olevaan kenttään. Voittajien tiedot luovutetaan
        Elinvoimainen järvilohi-hankkeelle nimikkolohen nimeämistä ja myöhempää kontaktointia varten.
      </p>
      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Jopas molskahti!" win>
            Viisi onnellista järvilohta kantaa tästä lähtien sinun nimeäsi, joten mitä suurimmat onnittelut!
            Merkintätietokantaan tulee siis viiden järvilohen merkintäsiruun sinun nimesi. Jos nimelläsi varustettu lohi
            tarttuu myöhemmin ”haaviin” ja sen tiedot raportoidaan, saat siitä tiedon itsellesi sähköpostilla. Samalla
            selviää, missä kala on tavattu ja miten se on kasvanut. Saat meiltä vielä erikseen sähköpostin, jossa
            kerromme asiasta lisää.
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Voi sentään!">
            <p>Kurkkaa, mitä huomenna luukusta löytyy!</p>
            <p>
              Nyt järvilohi uiskenteli toisen voittajan nimelliseen haaviin. Mutta tutustu kuitenkin
              järvilohihankkeeseen:{" "}
              <ExternalLink href="https://www.elinvoimainenjarvilohi.fi" children="www.elinvoimainenjarvilohi.fi" />
            </p>
            <p>
              Muistathan, että tänään on mahdollista kerätä kaikki palapelimme palat, koota palapeli ja osallistua
              S-ryhmän 250 euron arvoisen majoituslahjakortin arvontaan!
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku24
