import React from "react"
import Luukku from "./Luukku"
import * as styles from "./Joulukalenteri.module.scss"
import { pseudorandom, round } from "../utils"
import gsap from "gsap"
import { UserContext } from "../context/UserContext"
import { pala_imgs, STORAGE_KEY as palapeli_key } from "./Palapeli"
import { devMode } from "../pages/_etusivu-kalenteri"
import { gtag } from "./SEO"

const initLuukut = () => {
  // Luukusta tulevien palojen järjestys
  pseudorandom(2)
  const palat = [...Array(24).keys()].sort(() => pseudorandom() - 0.5)

  // Luukkujen järjestys
  pseudorandom(1)
  return [...Array(24).keys()]
    .map(ind => {
      return { ind: ind, status: luukkuStatus.LOCKED, palaInd: palat[ind] }
    })
    .sort(() => pseudorandom() - 0.5)
}

export const luukkuStatus = {
  AVAILABLE: "available",
  LOCKED: "locked",
  CURRENT: "current",
  OPEN: "open",
}

/** LocalStorage avain, johon avatut luukut tallennetaan */
export const STORAGE_KEY = "avatut"
/**
 *
 * @param onOpen Function
 * @returns
 */
const Joulukalenteri = props => {
  //console.log('Rendering Joulukalenteri', props)

  const [context, setContext] = React.useContext(UserContext)

  const [state, setState] = React.useState({ luukut: initLuukut() })
  const effect = React.useRef()
  /** Minkä vuoden joulukalenteri */
  const campaignYear = 2021

  React.useEffect(() => {
    const avatut = context[STORAGE_KEY]
    const luukut = state.luukut

    //console.log("avatut:", avatut)

    /** Nykyhetki */
    const date = new Date()

    luukut.forEach(luukku => {
      // Sallitaan oikeiden luukkujen avaaminen
      if (
        devMode ||
        date.getFullYear() > campaignYear ||
        (date.getFullYear() === campaignYear && luukku.ind < date.getDate() && date.getMonth() === 11)
      ) {
        if (luukku.ind + 1 === date.getDate()) {
          luukku.status = luukkuStatus.CURRENT
        } else {
          luukku.status = luukkuStatus.AVAILABLE
        }
      }
      // avataan aikaisemmin avatut
      if (typeof avatut[luukku.ind] === "number") {
        luukku.status = luukkuStatus.OPEN
      }
    })

    //console.log("luukut:", luukut)

    setState({ ...state, luukut })
  }, [context])

  const luukkuClick = (luukku, event) => {
    const luukut = state.luukut

    if (luukut && luukku.status !== luukkuStatus.LOCKED) {
      if (luukku.status === luukkuStatus.OPEN) {
        props.onOpen && props.onOpen(luukku)
      } else {
        luukku.status = luukkuStatus.OPEN
        setState({ ...state, luukut })

        const avatut = context[STORAGE_KEY]
        avatut[luukku.ind] = luukku.palaInd

        // Näytetään uusi palapelin pala
        effect.current.src = pala_imgs[luukku.palaInd]
        const pala = context[palapeli_key][luukku.palaInd]
        effect.current.style.objectPosition = `${round(pala.trgtX * 166.666 - 12.5, 3)}% ${round(
          pala.trgtY * 136.666 - 7,
          3
        )}%`

        setContext({ [STORAGE_KEY]: avatut })
        showEffect(event.currentTarget.getBoundingClientRect())

        setTimeout(() => {
          props.onOpen && props.onOpen(luukku)
        }, 2500)

        gtag("Kalenteri", "click", "Luukku", luukku.ind)
      }
    }
  }

  /**
   * Luukun avaamis extra efekti. Palapelin palanen lentää ulos.
   * @param {*} rect DOMRect
   */
  const showEffect = rect => {
    //console.log(rect)
    gsap.killTweensOf(effect.current)
    gsap.set(effect.current, {
      top: -50,
      left: -50,
      x: rect.left + window.scrollX,
      y: rect.top + window.scrollY,
    })
    gsap.fromTo(effect.current, { autoAlpha: 0 }, { duration: 1, delay: 0.5, autoAlpha: 1 })

    gsap.fromTo(effect.current, { scale: 0.5 }, { duration: 1, delay: 0.5, scale: 1 })
    gsap.to(effect.current, {
      delay: 1.5,
      duration: 0.5,
      scale: 0.5,
      x: window.innerWidth + window.scrollX - 150,
      y: window.innerHeight + window.scrollY - 100,
      autoAlpha: 0,
      ease: "power2.in",
    })

    //gsap.to(effect.current, { duration: 1.5, delay: 1.5, autoAlpha: 0, ease: "power3.in" })
  }

  //console.log(state)

  return (
    <>
      <div className={styles.container}>
        {state.luukut &&
          state.luukut.map((luukku, i) => {
            return (
              <Luukku
                key={luukku.ind}
                num={luukku.ind + 1}
                status={luukku.status}
                onClick={e => luukkuClick(luukku, e)}
              />
            )
          })}
      </div>
      <img ref={effect} className={styles.effect} alt="" />
    </>
  )
}

export default Joulukalenteri
