import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
//import * as styles from "./Luukku1.module.scss"

const Luukku1 = props => {
  //console.log('Rendering Luukku1', props)
  const nro = 1
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/1luukku_Joulu.png" alt="" />
      <h1>Näin Jou-Jou-JouLumme pyörähtää käyntiin! </h1>
      <p>
        Tiedätkö mitä piti tapahtua 20. elokuuta 2020? Tai mikä tapahtui Aviciin keikkojen kulisseissa? Vai haluatko
        kenties virittäytyä jo joulutunnelmaan? Näihin ja moniin muihin kysymyksiin saat vastaukset Nextorysta
        löytyvistä kirjoista.
      </p>
      <p>
        Tässä luukussa arvomme 6 kk lahjakortin Nextoryyn. Ihastuttavalla aineettomalla lahjalla saat puolen vuoden ajan
        lukea e-kirjoja sydämesi kyllyydestä tai uppoutua äänikirjojen maailmaan. Kokeile tuuriasi arvonnassa ja lumoudu
        lukemiseen!
      </p>

      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Hurraa, onneksi olkoon! Voitit 6 kuukauden lahjakortin Nextoryyn!" win>
            Lähetämme sinulle sähköpostiisi ohjeet voiton lunastamiseksi. Rentouttavia luku- ja kuunteluhetkiä!
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Pahus, tällä kertaa voitto ei osunut kohdallesi.">
            Lukemaan ja kuuntelemaan pääset kuitenkin Nextory-asiakaseduillamme:{" "}
            <a href="https://tiimi.lumme-energia.fi/pd/lifestyle/79/lue-enemman-ymparistoystavallisesti-kokeile-nextorya-nyt-45-paivaa-ilmaiseksi/  ">
              45 päivää ilmaista
            </a>{" "}
            luku- ja kuunteluaikaa uusille asiakkaille ja{" "}
            <a href="https://tiimi.lumme-energia.fi/pd/lifestyle/186/lue-enemman-ymparistoystavallisesti-vanhana-tilaajana-nyt-3-kk-puoleen-hintaan/ ">
              3kk puoleen hintaan
            </a>{" "}
            vanhoille Nextoryn asiakkaille. Äläkä unohda aloittaa palapelin palojen keräämistä, sillä pääpalkinto on
            huikea 250 euron arvoinen majoituslahjakortti S-ryhmälle!
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku1
