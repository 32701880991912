import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku6.module.scss"

const Luukku6 = props => {
  //console.log('Rendering Luukku6', props)
  const nro = 6
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/6luukku_Joulu.png" alt="" />
      <h1>Hyvää itsenäisyyspäivää!</h1>
      <p>
        Tänään tahdomme toivottaa hyvää joulua Suomelle ja suomalaiselle luonnolle lahjoittamalla Suomen
        luonnonsuojeluliiton ilmastotyöhön 300 euroa.
      </p>
      <p>
        Ilmastonmuutoksen ehkäiseminen on pitkäjänteistä työtä, johon meidän jokaisen panosta tarvitaan. Suomen
        luonnonsuojeluliitto puolustaa ilmaston lämpenemisen rajoittamista alle 1,5 asteeseen. Jotta pääsisimme yhdessä
        tavoitteisiin, on tärkeää suojella Suomen luontoa ja ekosysteemiä lisäämällä hiilinieluja ja uusiutuvan energian
        lähteitä.
      </p>
      <p>
        Me Lumme Energialla teemme työtä sen eteen, että luonnon monimuotoisuus saadaan säilytettyä. Osoita sinäkin
        tukesi osoitteessa <ExternalLink href="https://sll.fi" children="sll.fi" /> ja valitsemalla kotiisi Lumme
        Energian uusiutuvaa sähköä <ExternalLink href="https://www.lumme-energia.fi" children="www.lumme-energia.fi" />
      </p>
    </>
  )
}

export default Luukku6
