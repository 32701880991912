import React from "react"
import { Link } from "gatsby"

import * as styles from "./Footer.module.scss"

const Footer = props => {
  //console.log('Rendering Footer', props)
  return (
    <footer className={styles.footer}>
      <Link to="/saannot">Säännöt</Link>
      <Link to="/tietosuojaseloste">Tietosuojaseloste</Link>
    </footer>
  )
}

export default Footer
