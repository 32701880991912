import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//import * as styles from "./Luukku10.module.scss"

const Luukku10 = props => {
  //console.log('Rendering Luukku10', props)
  const nro = 10
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/10luukku_Joulu.png" alt="" />
      <h1>Näin Jou-Jou-JouLumme pyörähtää käyntiin! </h1>
      <p>
        Joulu. Tuo ihana jokavuotinen juhla, täynnä tehtävää ja väsättävää. Jotta jouluvalmistelusi tulisivat valmiiksi
        ilman hääräämistä häiritsevää stressiä, on hyvä valmistella yksi asia kerrallaan. Multitaskaamisen
        onnistumisprosentti kun voi olla mikä vain. Kysy itseltäsi, mikä päivän tärkein asia on ja priorisoi päivän
        tehtävälistaasi.
      </p>
      <p>
        Tässä muutama mindfulness-vinkki, joilla aamu lähtee onnistuneesti käyntiin – ja sen tuloksena myös
        jouluvalmistelut.
      </p>
      <ul>
        <li>
          Hyvä aamu alkaa tarkoituksella. Ennen kuin katsot puhelintasi, peset hampaat tai edes mietit sähköposteja,
          istahda hetkeksi sängyn reunalle. Sulje silmäsi, pidä selkä suorana, mutta hartiat rentoina.
        </li>
        <li>
          Ota kolme syvää hengitystä. Hengitä sisään nenän kautta ja suun kautta ulos. Anna hengityksen tasaantua
          rauhallisesti.
        </li>
        <li>
          Mieti päivääsi. Kysy, mitä haluan tänään saada aikaiseksi? Miten pidän parempaa huolta itsestäni tai
          läheisistäni? Mistä saan suurimman hyödyn?
        </li>
        <li>
          Aseta päiväsi tavoite. Esimerkiksi: ”Käyn hakemassa joulukuusen ja etsin koristeet varastosta. Lupaan syödä
          hyvin ja urheilla.”
        </li>
        <li>
          Palaa päivän mittaan tavoitteeseesi. Pysähdy hetkeksi, hengitä ja muistele, mikä olikaan tänään aikeesi.
        </li>
      </ul>
    </>
  )
}

export default Luukku10
