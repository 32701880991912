import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku12.module.scss"

const Luukku12 = props => {
  //console.log('Rendering Luukku12', props)
  const nro = 12
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/12luukku_Joulu.png" alt="" />
      <h1>Mitä jos kaulisit kaverille kipon? </h1>
      <p>
        Lupauduitko antamaan joululahjaksi jotain upeaa ja itsetehtyä? Huomasitko, että kiireen ohessa aika jäikin
        kortille? Hupsis! Onneksi itsekovettuvasta savesta taiot joululahjat kuin itsestään – sitä kun ei tarvitse
        polttaa tai lasittaa. Luonnonsavesta muovattu kippo on kestävä ja vastuullinen lahja, joka on täynnä persoonaa.
        Lisäksi saven pyörittely sormenpäissä on uskomattoman terapeuttista!
      </p>
      <p>
        Itsekovettuvasta savesta kaulit helposti koristekipon, muovailet kynttilänjalan tai veistät vaikka
        kuusenkoristeen. Käytä valmista kulhoa tai lautasta mallina ja koristele kokonaisuus askartelumaalilla.
      </p>

      <p>
        Savea voit ostaa esimerkiksi täältä:
        <br />
        <ExternalLink
          href="https://www.sinelli.fi/itsekovettuva-savi-vaaleanharmaa-1000g-1-pkk"
          children="https://www.sinelli.fi/itsekovettuva-savi-vaaleanharmaa-1000g-1-pkk"
        />
      </p>
    </>
  )
}

export default Luukku12
