import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"

import "../styles/global.scss"
import Kalenterisivu, { devMode } from "./_etusivu-kalenteri"
import SEO from "../components/SEO"

export const now = Date.now() - new Date().getTimezoneOffset() * 60 * 1000
export const goLive = Date.UTC(2021, 11, 1, 0, 0)
export const endOfChristmas = Date.UTC(2021, 11, 25, 0, 0)
export const endOfCampaign = Date.UTC(2022, 0, 4, 0, 0)

const IndexPage = () => {
  //const now = Date.now() - new Date().getTimezoneOffset() * 60 * 1000
  //const goLive = Date.UTC(2021, 11, 1, 0, 0)
  //console.log(now, goLive, now - goLive)

  // Kampanja käynnissä
  if (now > goLive || devMode) return <Kalenterisivu />

  // Kampanja ei vielä alkanut
  return (
    <>
      <SEO />
      <Header />
      <main>
        <p className="pre-heading">Lumme Energia esittää:</p>
        <h1>JouLumme vastustamat&shy;tomin joulukalenteri</h1>
        <p>Hups, olet paikalla etuajassa! Kalenterimme ensimmäinen luukku avautuu 1.12.</p>
        <p>Luvassa on ihania palkintoja, tärkeitä lahjoituksia, hauskaa tekemistä ja hilpeitä hetkiä!</p>
      </main>
      {/* <Footer /> */}
    </>
  )
}

export default IndexPage
