import React from "react"
//import * as styles from "./FormDisclaimer.module.scss"

const FormDisclaimer = props => {
  //console.log('Rendering FormDisclaimer', props)
  return (
    <p className="form-ingress">
      {props.children ||
        `Syötä sähköpostisi alla olevaan kenttään ja katso suosiiko onni juuri sinua. Käytämme sähköpostiasi vain joulukalenterin arvontaan liittyvään kontaktointiin.​`}
    </p>
  )
}

export default FormDisclaimer
