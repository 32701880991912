import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
//import * as styles from "./Luukku18.module.scss"

const Luukku18 = props => {
  //console.log('Rendering Luukku18', props)
  const nro = 18
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/18luukku_Joulu.png" alt="" />
      <h1>Kilisee, kilisee, Fazerin Siniset ja Maraboun suklaalevyt.</h1>
      <p>Osallistu arvontaan, tänään 20 onnekasta voittaa suut makeaksi! </p>
      <p>
        Miksikö suklaata? Sen lisäksi, että suklaa maistuu taivaalliselta, se lisää kehon hyvän olon hormoneita, jotka
        lievittävät stressiä. Eli mitä jos haukkaisimme herkkua edes parin palan verran silloin, kun mieliteko yllättää?
      </p>
      <Lomake
        apiUrl="play"
        draw={nro}
        seed={nro}
        disabled={!devMode && new Date().getDate() !== nro}
        winContent={
          <Voittoviesti title="Onnea, voitit ilolahjan, eli levyllisen suklaata!" win>
            Lähetämme lähipäivinä sähköpostiisi oman Ärräkoodisi, jolla saat lunastaa voittosuklaasi. Sitten vain kipin
            kapin lähimmälle R-kioskille!
          </Voittoviesti>
        }
        loseContent={
          <Voittoviesti title="Pahus, voitto vierähti toiseen osoitteeseen.">
            <p>Kurkkaa, mitä huomenna luukusta löytyy!</p>
            <p>
              Muistathan napata talteen palapelimme tämänpäiväisen palan! Kaikki palat keränneenä ja pelin koonneena
              pääset osallistumaan S-ryhmän 250 euron arvoisen majoituslahjakortin arvontaan.
            </p>
          </Voittoviesti>
        }>
        <FormDisclaimer />
      </Lomake>
    </>
  )
}

export default Luukku18
