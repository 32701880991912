import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lomake from "../Lomake"
import Voittoviesti from "../Voittoviesti"
import FormDisclaimer from "../FormDisclaimer"
import { devMode } from "../../pages/_etusivu-kalenteri"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku23.module.scss"

const Luukku23 = props => {
  //console.log('Rendering Luukku23', props)
  const nro = 23
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/23_luukku.png" alt="" />

      <p>
        Lumme Energian yksi tärkeimmistä arvoista on jokaisesta ihmisestä välittäminen ja koemme suurta vastuuta niiden
        puolesta, joilla ei ole mahdollisuutta itseään auttaa tai puolustaa. Jokaisella lapsella ja nuorella pitäisi
        olla oikeus saada laadukasta huolenpitoa. Se tarkoittaa turvaa, mahdollisuutta kasvaa ja kehittyä sekä sitä,
        että vahvat ja luotettavat tukiverkostot ovat kunnossa. SOS-Lapsikylä on lastensuojelujärjestö, joka tarjoaa
        tukea ja apua siihen, että jokaisella lapsella on kasvaa mahdollisuuksiensa mittoihin. Tehdään töitä yhdessä
        onnellisemman lapsuuden puolesta.
      </p>
      <p>
        Me teimme 300 euron joululahjoituksen SOS-Lapsikylälle lastensuojelun hyväksi. Lahjoita sinäkin lasten ja
        nuorten tukitoimintaan osoitteessa <ExternalLink href="https://sos-lapsikyla.fi" children="sos-lapsikyla.fi" />
      </p>
    </>
  )
}

export default Luukku23
