import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ExternalLink from "../ExternalLink"
//import * as styles from "./Luukku2.module.scss"

const Luukku2 = props => {
  //console.log('Rendering Luukku2', props)
  const nro = 2
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/2luukku_Joulu.png" alt="" />
      <p>
        Voisipa joulunaika olla kaikille yhtä riemua ja lepoa. Monissa perheissä joulu kuitenkin aiheuttaa päinvastoin
        stressiä ja jopa ahdistusta. Helpottaaksemme jonkun perheen jouluhankintoja, teimme 300 euron lahjoituksen Hope
        ry:lle vähävaraisten lasten ja nuorten hyväksi. Hope avustaa konkreettisin toimin jakamalla vaatteita ja leluja,
        tarjoamalla mahdollisuuden harrastaa sekä toimittamalla ruoka-apua sitä tarvitseville.
      </p>
      <p>
        Auttaminen ei aina vaadi rahallista panostusta. Jos sinulla on esimerkiksi aikaa vapaaehtoistyöhön tai
        mahdollisuus lahjoittaa hyvänkuntoisia lasten ja nuorten vaatteita ja tarvikkeita, voit omalla panoksellasi
        tukea niitä, jotka apua tarvitsevat. Lue lisää tavoista, joilla voit auttaa:{" "}
        <ExternalLink href="https://hopeyhdistys.fi" children="hopeyhdistys.fi" />
      </p>
    </>
  )
}

export default Luukku2
