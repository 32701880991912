/** Muotoilee string arrayn käytettäväksi className arvona */
export const cls = (...rest) => rest.filter(Boolean).join(" ")

const { PI, abs } = Math
let _seed
/**
 * Random numerot aina samassa järjestyksessä per seed. Alusta antamalla parametri seed, seuraava satunnaisluku ilman parametria.
 * @param seed Numero
 */
export const pseudorandom = seed => {
  _seed = abs(seed || _seed || 1234)
  const r = (_seed % PI) / PI
  _seed += r * PI * 10
  return r
}

/** Round number to precision */
export const round = (val, precision) => {
  precision = Math.pow(10, precision || 0)

  if (precision >= 0) return Math.round(val * precision) / precision

  return Math.round(val / precision) * precision
}

export const reset = () => {
  localStorage.clear()
  window.location.reload()
}
