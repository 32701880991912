import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//import * as styles from "./Luukku3.module.scss"

const Luukku3 = props => {
  //console.log('Rendering Luukku3', props)
  const nro = 3
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/3luukku_Joulu.png" alt="" />
      <p>
        Muistathan raapustaa joulukortit riittävän ajoissa? Vielä on aikaa kiikuttaa jouluisella postimerkillä
        varustettu jouluterveisesi Postiin. Viimeinen jättöpäivä on 14.12.
      </p>
      <p>
        Mitä jos tänä jouluna lähettäisitkin valmiiden toivotusten sijaan itse tekemäsi runon? Sehän on onneksi varsin
        helppoa. Otetaan pohjaksi haiku. Haiku on kolmisäkeinen runo, jossa on 17 tavua. Tavut on jaettu säkeiksi
        5-7-5-jaottelulla.
      </p>
      <p>Tässä esimerkki:</p>
      <p style={{ fontSize: "1.2em", fontStyle: "italic" }}>
        On jou-lun tai-kaa (5)
        <br />
        Taas i-lo nau-ru rai-kaa (7)
        <br />
        sy-dä-meen as-ti (5)
      </p>
      <p>
        Tämän kohtalaisesti tuotetun esimerkin turvin sinäkin uskallat pistää paremmaksi, eikö vain? Nyt runosuoni
        sykkimään!
      </p>
      <p>
        Psst! Muista kerätä päivän palapelin pala. Kaikki kerättyäsi voit osallistua S-ryhmän 250 euron arvoisen
        majoituslahjakortin arvontaan.
      </p>
    </>
  )
}

export default Luukku3
