import React from "react"
import { StaticImage } from "gatsby-plugin-image"
//import * as styles from "./Luukku16.module.scss"

const Luukku16 = props => {
  //console.log('Rendering Luukku16', props)
  const nro = 16
  return (
    <>
      <p className="luukku-nro">{nro}.</p>
      <StaticImage src="../../images/luukut/16luukku_Joulu.png" alt="" />
      <h1>Villasukat jalassa? Hyvä!</h1>
      <p>Nyt jumpataan villasukkajumppaa. Tee liikkeitä 20 toiston kierrolla ja niin monta kierrosta kuin jaksat.</p>
      <ul>
        <li>
          Laita kädet lattiaan ja anna jalkojen liukua taakse, jotta olet lähes lankkuasennossa. Lähde ”kiipeämään”
          jaloilla. Anna jalkojen liukua kunnolla, jotta liikeradat ovat pitkiä. Hikihän tässä tulee!
        </li>
        <li>
          ee askelkyykky taakse ja sivulle, jalkoja maassa liu’uttaen. Tee liike ensin toisella jalalla ja sitten
          toisella. Ota paikallaan olevasta jalasta sukka pois, jotta jalkapohja pitää tukevammin. Tehokas liike pepulle
          ja reisille!
        </li>
        <li>
          Mene selinmakuulle ja laita jalat koukkuun. Nosta peppu ylös maasta. Anna jalkojen liukua vuorotellen
          eteenpäin, jolloin paino pysyy toisella jalalla. Takuutehokas coretreeni!
        </li>
      </ul>
    </>
  )
}

export default Luukku16
